import React from 'react'
import Notification from '@bufferapp/ui/Notification'
import styled from 'styled-components'

const NotificationHolder = styled.div`
  position: relative;
  top: -9px;
`

const Notifications = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'profilesWithFreshData' implicitly... Remove this comment to see the full error message
  profilesWithFreshData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'refreshProfiles' implicitly has a... Remove this comment to see the full error message
  refreshProfiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeFreshDataAlert' implicitly h... Remove this comment to see the full error message
  closeFreshDataAlert,
}) => {
  return (
    <NotificationHolder>
      {profilesWithFreshData.length > 0 && (
        <Notification
          text={`We've just received word that there is fresh data for the following social account${
            profilesWithFreshData.length > 1 ? 's' : ''
          }: ${profilesWithFreshData.reduce(
            // @ts-expect-error TS(7006) FIXME: Parameter 'res' implicitly has an 'any' type.
            (res, profile, index) =>
              `${res}${
                index > 0 && profilesWithFreshData.length > 2 ? ', ' : ''
              } ${
                index > 0 && index === profilesWithFreshData.length - 1
                  ? 'and'
                  : ''
              } ${profile ? profile.username : ''}`,
            '',
          )}!`}
          type="action"
          action={{
            label: 'Refresh Now',
            callback() {
              refreshProfiles()
              closeFreshDataAlert()
            },
          }}
          onClose={() => {
            closeFreshDataAlert()
          }}
        />
      )}
    </NotificationHolder>
  )
}

export default Notifications
