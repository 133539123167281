import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ShopifyTotals from '~/shopify-totals'
import ShopifySources from '~/shopify-sources'
import ShopifyProducts from '~/shopify-products'

const ShopifyOverview = () => (
  <Fragment>
    <ShopifyTotals />
    <ShopifySources />
    <ShopifyProducts />
  </Fragment>
)

ShopifyOverview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string,
    }),
  }).isRequired,
}

export default connect()(ShopifyOverview)
