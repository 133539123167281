import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

function getReleaseState() {
  const hostname = window.location.host
  if (hostname.includes('local.buffer.com')) {
    return 'local'
  } else if (hostname.includes('dev.buffer.com')) {
    return 'staging'
  }
  return 'production'
}

Bugsnag.start({
  collectUserIp: false,
  apiKey: '77b8ac3a6348d1341ed6b27e9d2ae75c',
  // @ts-ignore
  plugins: [new BugsnagPluginReact()],
  releaseStage: getReleaseState(),
})

// @ts-ignore
export default Bugsnag.getPlugin('react').createErrorBoundary(React)
