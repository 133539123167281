import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavSidebar from '~/nav-sidebar'
import ReportsList from '~/report-list'
import styled from 'styled-components'
import {
  LayoutPage,
  LayoutSidebar,
  LayoutLeft,
  LayoutRight,
  LayoutContents,
  ContentCard,
  Watermark,
} from '~/shared-components'

import { gray, white } from '@bufferapp/ui/style/colors'
import Text from '@bufferapp/ui/Text'
import { UserIsAdmin } from '~/account'

const HeaderHolder = styled.header`
  background: ${white};
  border-bottom: 1px solid ${gray};
  padding: 1.375rem 1.5rem 0.5rem;
  border-radius: 3px 3px 0 0;

  h2 {
    margin: 0;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'location' implicitly has an 'any'... Remove this comment to see the full error message
const ReportsPage = ({ location }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <LayoutPage full>
    <LayoutSidebar>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ route: any; }' is not assignable to type '... Remove this comment to see the full error message */}
      <NavSidebar route={location.pathname} />
    </LayoutSidebar>
    <LayoutLeft />
    <UserIsAdmin hasPageContent={true}>
      <LayoutContents>
        <ContentCard
          subtabs={
            <HeaderHolder>
              <Text type="h2">Reports</Text>
              <Text type="p">
                Your reports are automatically updated every day
              </Text>
            </HeaderHolder>
          }
        >
          {/* @ts-expect-error TS(2741) FIXME: Property 'selectReport' is missing in type '{}' bu... Remove this comment to see the full error message */}
          <ReportsList />
          <Watermark />
        </ContentCard>
      </LayoutContents>
    </UserIsAdmin>
    <LayoutRight />
  </LayoutPage>
)

ReportsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default connect()(ReportsPage)
