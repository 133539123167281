/* eslint-disable */

function trackPageChangeEvent(currentPath: string = '/'): void {
  // @ts-expect-error
  window.Beacon('event', {
    type: 'page-viewed',
    url: document.location.href,
    title: `${document.title}: ${currentPath}`,
  })
}

function updateHelpScoutBeacon(currentPath: string = '/'): void {
  // @ts-expect-error
  if (window.Beacon) {
    trackPageChangeEvent(currentPath)
    // @ts-expect-error
    window.Beacon('suggest')
  }
}

export const HelpScoutBeacon = {
  updateHelpScoutBeacon,
}
