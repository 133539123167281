import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Tooltip from '@bufferapp/ui/Tooltip'
import Text from '@bufferapp/ui/Text'
import { TruncatedNumber } from '~/shared-components'
import {
  fontFamily,
  fontWeightBold,
  fontSize,
  lineHeight,
} from '@bufferapp/ui/style/fonts'
import { blueDark } from '@bufferapp/ui/style/colors'
import dayjs from 'dayjs'

const baseFontStyles = css`
  font-family: ${fontFamily};
  font-weight: ${fontWeightBold};
  font-size: ${fontSize};
  line-height: ${lineHeight};
`

const List = styled.ol`
  list-style-type: none;
  display: flex;
  margin-bottom: 32px;
  position: relative;
  padding-left: 0px;
  height: 145px;
  justify-content: space-around;

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid #c2ced9;
    padding-bottom: 8px;
    position: absolute;
    bottom: 24px;
  }
`

const Item = styled.li`
  display: flex;
  height: 100%;
  z-index: 1;
  justify-content: center;
  width: 28px;

  & > div {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Themed... Remove this comment to see the full error message
    props.timezone &&
    css`
      &:nth-child(3n + 1) label {
        color: rgb(103, 103, 103);
      }

      &:first-child label,
      &:nth-child(22) label {
        color: transparent;
      }

      &:nth-child(-n + 2):hover {
        &::after {
          content: '';
          display: block;
          width: 100px;
          height: 16px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: white;
          transition: background-color 0.2s ease;
        }
      }

      &:nth-child(
          n
            + ${calculateWhichChildrentoHide(
              // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Themed... Remove this comment to see the full error message
              props.timezone,
            )}
        ):hover {
        &::after {
          content: '';
          display: block;
          width: 230px;
          height: 16px;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: white;
          transition: background-color 0.2s ease;
        }
      }
    `}
`

// @ts-expect-error TS(7006) FIXME: Parameter 'timezone' implicitly has an 'any' type.
const calculateWhichChildrentoHide = (timezone) => {
  // these numbers come from trial and error
  switch (true) {
    case timezone.length <= 6:
      return 24
    case timezone.length >= 7 && timezone.length <= 12:
      return 23
    case timezone.length >= 13 && timezone.length <= 18:
      return 22
    case timezone.length >= 19 && timezone.length <= 24:
      return 21
    default:
      return 20
  }
}

const Bar = styled.button`
  height: 100%;
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isDays' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.isDays ? '92px' : '28px'};
  background: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    props.isSelected
      ? '#6b81ff'
      : 'linear-gradient(180deg, rgba(107,129,255,0.75) 0%, rgba(107,129,255,1) 100%)'};
  transition: all 0.1s ease-in-out;
  border: 0 none;
  border-radius: 2px 2px 0px 0px;
  margin-bottom: 10px;

  :focus {
    opacity: 1;
    outline: none;
  }

  :active {
    background: ${blueDark};
  }

  ${Item}:hover & {
    opacity: 1;
    cursor: pointer;
  }
`

const Label = styled.label`
  ${baseFontStyles}

  color: ${(props) =>
    !(
      // @ts-expect-error TS(2339) FIXME: Property 'isHours' does not exist on type 'ThemedS... Remove this comment to see the full error message
      props.isHours
    )
      ? 'rgb(103, 103, 103)'
      : 'transparent'};
  opacity: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    props.isSelected ? '1' : '0.75'};
  transition: opacity 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-weight: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    props.isSelected ? 700 : 500};
  height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.height}%;
  min-height: 45px;
  transition: height 0.2s ease-out;

  ${Item}:hover & {
    color: rgb(103, 103, 103);
    opacity: 1;
    z-index: 1;
  }
`

const DateTimezoneInfo = styled.span`
  ${baseFontStyles}
  position: absolute;
  font-weight: 500;
  bottom: 0;
  right: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'right' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.right ? 0 : ''};
  color: rgba(103, 103, 103, 0.75);
`

const Highlight = styled.span`
  font-family: ${fontFamily};
  font-weight: 900;
  font-size: 14px;
  line-height: ${lineHeight};
  color: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'positive' does not exist on type 'Themed... Remove this comment to see the full error message
    props.positive ? '#21f32a' : '#e0364f'};
`

const LabelWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;

  :hover {
    cursor: pointer;
  }
`

export class BarChart extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      lastSelectedDay: '',
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  onHoverOverBar(label) {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedDay, selectNewDay } = this.props

    if (!selectNewDay) return
    const copyOfDay = selectedDay
    this.setState({
      lastSelectedDay: copyOfDay,
    })
    selectNewDay(label)
  }

  onLeaveCurrentBar() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedDay, selectNewDay } = this.props
    // @ts-expect-error TS(2339) FIXME: Property 'lastSelectedDay' does not exist on type ... Remove this comment to see the full error message
    const { lastSelectedDay } = this.state

    if (!selectNewDay) return
    if (selectedDay === lastSelectedDay) {
    } else {
      selectNewDay(lastSelectedDay)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  onClickOnCurrentBar(label) {
    // @ts-expect-error TS(2339) FIXME: Property 'selectNewDay' does not exist on type 'Re... Remove this comment to see the full error message
    const { selectNewDay } = this.props

    if (!selectNewDay) return
    this.setState({
      lastSelectedDay: label,
    })
    selectNewDay(label)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'percentage' implicitly has an 'any' typ... Remove this comment to see the full error message
  createTooltipLabel(percentage) {
    return (
      <React.Fragment>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <Highlight positive={Math.sign(percentage) === 1}>
          <TruncatedNumber showPercentSign absoluteValue roundDecimals>
            {percentage}
          </TruncatedNumber>
        </Highlight>
        <Text type="label" color="white">{` ${
          Math.sign(percentage) === 1 ? 'above' : 'below'
        } average post reach`}</Text>
      </React.Fragment>
    )
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  createBar(metric, height) {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedDay, selectNewDay } = this.props
    const label = metric.weekday || metric.hour

    return (
      <Bar
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        height={height}
        isSelected={label === selectedDay}
        isDays={selectNewDay}
      />
    )
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { metrics, timezone, selectedDay, selectNewDay, averageReach } =
      this.props
    // grab the highestValue so we can calculate the height of the bars as percentages
    // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
    const highestValue = Math.max(...metrics.map((m) => m.prediction))

    return (
      <div style={{ position: 'relative' }}>
        {!selectNewDay && <DateTimezoneInfo>{selectedDay}</DateTimezoneInfo>}
        <List>
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type. */}
          {metrics.map((metric, index) => {
            const height = (metric.prediction / highestValue) * 100
            const label = metric.weekday || metric.hour

            const singleLabel = (
              <LabelWrapper>
                <Label
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  height={height}
                  isHours={!!timezone}
                  isSelected={label === selectedDay}
                >
                  {this.createBar(metric, height)}
                  {timezone ? dayjs().hour(label).format('ha') : label}
                </Label>
              </LabelWrapper>
            )

            if (selectNewDay) {
              return (
                <Item
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  timezone={timezone}
                  onClick={() => this.onClickOnCurrentBar(label)}
                  onMouseEnter={() => this.onHoverOverBar(label)}
                  onMouseLeave={() => this.onLeaveCurrentBar()}
                  key={index}
                >
                  {singleLabel}
                </Item>
              )
            } else {
              // to calculate the percentage increase you subtract the value from the lowest weekly value
              const increase = metric.prediction - averageReach
              // then you divide the increase by the value and multiply by 100 to get it as a percent
              const percentage =
                metric.prediction === 0
                  ? 0
                  : (increase / metric.prediction) * 100
              const tooltipLabel = this.createTooltipLabel(percentage)
              return (
                <Item
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  timezone={timezone}
                  onClick={() => this.onClickOnCurrentBar(label)}
                  onMouseEnter={() => this.onHoverOverBar(label)}
                  onMouseLeave={() => this.onLeaveCurrentBar()}
                  key={index}
                >
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element... Remove this comment to see the full error message */}
                  <Tooltip customLabel={tooltipLabel} position="top">
                    {singleLabel}
                  </Tooltip>
                </Item>
              )
            }
          })}
        </List>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        {timezone && <DateTimezoneInfo right>{timezone}</DateTimezoneInfo>}
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
BarChart.propTypes = {
  metrics: PropTypes.array,
  selectedDay: PropTypes.string,
  selectNewDay: PropTypes.func,
  timezone: PropTypes.string,
  averageReach: PropTypes.number,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
BarChart.defaultProps = {
  metrics: [],
  selectedDay: '',
  timezone: '',
  averageReach: 0,
}
