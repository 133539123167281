import React from 'react'
import Banner from '@bufferapp/ui/Banner'
import { channelsRedirectUrl } from '~/account'

// @ts-expect-error TS(7031) FIXME: Binding element 'hasInvalidProfiles' implicitly ha... Remove this comment to see the full error message
const TopBanner = ({ hasInvalidProfiles }) => {
  return (
    <React.Fragment>
      {hasInvalidProfiles && (
        <Banner
          // @ts-expect-error TS(2322) FIXME: Type '{ themeColor: string; text... Remove this comment to see the full error message
          themeColor="orange"
          text="Uh oh! Some of your social channels expired. Please refresh them to continue getting analytics."
          actionButton={{
            label: 'Reconnect',
            action: () => {
              // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
              window.location = channelsRedirectUrl()
            },
          }}
          /* eslint-disable-next-line */
          onCloseBanner={() => console.log('Banner closed!')}
        />
      )}
    </React.Fragment>
  )
}

export default TopBanner
