import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Summary from '~/answers-summary'
import Chart from '~/answers-chart'
import BestTime from '~/best-time'
import Resources from '~/resources'

// @ts-expect-error TS(7031) FIXME: Binding element 'match' implicitly has an 'any' ty... Remove this comment to see the full error message
const AnswersTab = ({ match }) => {
  return (
    <Fragment>
      <Summary />
      {match.params.channel === 'instagram' && <BestTime />}
      <Chart metricType="day" />
      <Chart metricType="type" />
      <Chart metricType="frequency" />
      <Resources referral={'answers'} channel={match.params.channel} />
    </Fragment>
  )
}

AnswersTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }).isRequired,
}

export default connect()(AnswersTab)
