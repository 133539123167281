import { connect } from 'react-redux'
import store from '~/store'
import reducer, { actions } from './reducer'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import middleware from './middleware'
import BestTime from './components/BestTime'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    loading: state.bestTime.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    hasError: state.bestTime.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    selectedDay: state.bestTime.selectedDay,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    topDays: state.bestTime.topDays,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    days: state.bestTime.days,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    hours: state.bestTime.hours,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    averageReach: state.bestTime.averageReach,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    timezone: state.bestTime.timezone,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfileId,
    // @ts-expect-error TS(2339) FIXME: Property 'bestTime' does not exist on type 'Defaul... Remove this comment to see the full error message
    isDefault: state.bestTime.isDefault,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'newDay' implicitly has an 'any' type.
    selectNewDay: (newDay) => dispatch(actions.selectNewDay(newDay)),
  }),
)(BestTime)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('bestTime', reducer)
addMiddleware(middleware)
