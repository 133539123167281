import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PostsTable from '~/posts-table'
import HashtagsTable from '~/hashtags-table'
import PostsSummaryTable from '~/posts-summary-table'
import BoostedPostsNotice from '~/boosted-posts-notice'

const PostsTab = () => (
  <Fragment>
    <BoostedPostsNotice />
    {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
    <PostsSummaryTable />
    {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
    <HashtagsTable />
    {/* @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'never'. */}
    <PostsTable />
  </Fragment>
)

PostsTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string,
    }),
  }).isRequired,
}

export default connect()(PostsTab)
