import React from 'react'
import PropTypes from 'prop-types'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  ChartHelper,
  Suggestions,
} from '~/shared-components'
import Text from '@bufferapp/ui/Text'
import { Can, BEST_TIME } from '~/account'
import Title from '../Title'
import { BarChart } from '../BarChart'

class BestTime extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps, prevState, snapshot) {
    // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (prevProps.profile !== this.props.profile) {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
      hasError,
      // @ts-expect-error TS(2339) FIXME: Property 'days' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      days,
      // @ts-expect-error TS(2339) FIXME: Property 'hours' does not exist on type 'Readonly<... Remove this comment to see the full error message
      hours,
      // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
      selectedDay,
      // @ts-expect-error TS(2339) FIXME: Property 'selectNewDay' does not exist on type 'Re... Remove this comment to see the full error message
      selectNewDay,
      // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
      timezone,
      // @ts-expect-error TS(2339) FIXME: Property 'averageReach' does not exist on type 'Re... Remove this comment to see the full error message
      averageReach,
      // @ts-expect-error TS(2339) FIXME: Property 'topDays' does not exist on type 'Readonl... Remove this comment to see the full error message
      topDays,
      // @ts-expect-error TS(2339) FIXME: Property 'isDefault' does not exist on type 'Readonl... Remove this comment to see the full error message
      isDefault,
    } = this.props
    let content = null
    if (hasError) {
      content = null
    } else if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
      content = <Loading active noBorder />
    } else if (days.length === 0) {
      content = <NoData chartName="best-time-to-post" />
    } else {
      content = (
        <div
          style={{ position: 'relative', margin: '0 16px 24px' }}
          id="js-dom-to-png-best-time-to-post"
        >
          <Suggestions>
            <Text type="h3">
              {topDays[0].value} on {topDays[0].key}
            </Text>
            {isDefault ? (
              <Text type="label">
                This data shown is based on Buffer’s research from thousands of
                small businesses and creators. As you use Buffer more you{'’'}ll
                get personalized suggestions for your channel.
              </Text>
            ) : (
              <Text type="label">
                This looks like the best time for you to maximize your reach.
                You can also try {topDays[1].value} on {topDays[1].key} and{' '}
                {topDays[2].value} on {topDays[2].key}, too.
              </Text>
            )}
          </Suggestions>
          <BarChart
            // @ts-expect-error TS(2322) FIXME: Type '{ metrics: any; selectedDay: any; selectNewD... Remove this comment to see the full error message
            metrics={days}
            selectedDay={selectedDay}
            selectNewDay={selectNewDay}
          />
          <BarChart
            // @ts-expect-error TS(2322) FIXME: Type '{ metrics: any; selectedDay: any; timezone: ... Remove this comment to see the full error message
            metrics={hours}
            selectedDay={selectedDay}
            timezone={timezone}
            averageReach={averageReach}
          />
        </div>
      )
    }
    return (
      // @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message
      <Can {...{ BEST_TIME }}>
        <ChartCard hasError={hasError}>
          <ChartHeader>
            <Title />
            <ChartHelper
              text={
                'Your predicted reach is calculated using our proprietary statistical model that includes data from your previous posts and your followers’ activity.'
              }
            />
          </ChartHeader>
          {content}
        </ChartCard>
      </Can>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
BestTime.defaultProps = {
  days: [],
  hours: [],
  selectedDay: '',
  timezone: '',
  averageReach: null,
  profile: '',
  isDefault: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
BestTime.propTypes = {
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  days: PropTypes.array,
  hours: PropTypes.array,
  selectedDay: PropTypes.string,
  selectNewDay: PropTypes.func,
  timezone: PropTypes.string,
  averageReach: PropTypes.number,
  profile: PropTypes.string,
  isDefault: PropTypes.bool,
}

export default BestTime
