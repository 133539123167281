import React from 'react'
import { EmptyState, InfoIcon, Button } from '@buffer-mono/popcorn'

import styles from './InstagramFacebookReAuthMessage.module.css'

export function InstagramFacebookReAuthMessage({
  name,
  channelId,
}: {
  name: string
  channelId: string
}): JSX.Element {
  return (
    <div className={styles.base}>
      <EmptyState size="medium" variant="primary">
        <EmptyState.Icon>
          <InfoIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>
          Unlock Instagram Analytics for '{name}'
        </EmptyState.Heading>
        <EmptyState.Description>
          To access Instagram analytics, link your Instagram to a Facebook Page.
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button
            onClick={(): void => {
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.instagramFacebookReAuth, {
                channelId,
              })
            }}
          >
            Show Me How
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    </div>
  )
}
